/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'

Vue.component('vx-tooltip', () => import('./layouts/components/vx-tooltip/VxTooltip.vue'))
Vue.component('vx-card', () => import('./components/vx-card/VxCard.vue'))
Vue.component('vx-list', () => import('./components/vx-list/VxList.vue'))
Vue.component('vx-breadcrumb', () => import('./layouts/components/VxBreadcrumb.vue'))
Vue.component('feather-icon', () => import('./components/FeatherIcon.vue'))
Vue.component('vx-input-group', () => import('./components/vx-input-group/VxInputGroup.vue'))
Vue.component('aab-vie-ongoing-projet', () => import('./components/aab-vie/aab-vie-ongoing-project/index.vue'))
Vue.component('aab-vie-contrat', () => import('./components/aab-vie/aab-vie-contrat/index.vue'))
Vue.component('aab-vie-aae', () => import('./components/aab-vie/aab-vie-contrat/aab-vie-AAE/index.vue'))
Vue.component('aab-vie-abq', () => import('./components/aab-vie/aab-vie-contrat/aab-vie-ABQ/index.vue'))
Vue.component('aab-vie-ahr', () => import('./components/aab-vie/aab-vie-contrat/aab-vie-AHR/index.vue'))
Vue.component('aab-vie-apf', () => import('./components/aab-vie/aab-vie-contrat/aab-vie-APF/index.vue'))
Vue.component('aab-vie-aaf', () => import('./components/aab-vie/aab-vie-contrat/aab-vie-AAF/index.vue'))
Vue.component('aab-vie-subscribe-contrat-evolution', () => import('./components/aab-vie/aab-vie-contrat/ContratEvolution/index.vue'))
Vue.component('aab-vie-subscribe-general-information', () => import('./components/aab-vie/aab-vie-contrat/GeneralInformations/index.vue'))
Vue.component('aab-vie-subscribe-receipt', () => import('./components/aab-vie/aab-vie-contrat/Receipt/index.vue'))
Vue.component('aab-vie-subscribe-relaunch', () => import('./components/aab-vie/aab-vie-contrat/Relaunch/index.vue'))





// v-select component
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 h-4 mt-1'
      }
    }),
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 h-5'
      }
    }),
  },
});

Vue.component(vSelect)
































